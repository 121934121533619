import { ErpTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();
  const status = [
    {
      value: false,
      label: '否',
    },
    {
      value: true,
      label: '是',
    },
  ];
  const applyTypeOption = [
    {
      value: 1,
      label: '预付款',
    },
    {
      value: 2,
      label: '尾款',
    },
  ];
  const columnList: ErpTableOptions[] = [
    {
      label: '申请编号',
      prop: 'applyNo',
      minWidth: 131,
      value: '',
      type: 'input',
    },
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 143,
      value: '',
      type: 'input',
    },
    {
      label: '是否代采',
      prop: 'isNeedCg',
      propDesc: 'isNeedCgDesc',
      minWidth: 130,
      value: '',
      type: 'signSelect',
      options: status,
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      minWidth: 160,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '供应商',
      prop: 'supplierName',
      minWidth: 160,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 183,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',
      minWidth: 103,
      value: '',
      type: 'input', // 筛选类型 input 输入项
    },
    {
      label: '付款类型',
      prop: 'applyType',
      propDesc: 'applyTypeDesc',
      type: 'signSelect',
      options: applyTypeOption,
      value: '',
      minWidth: 130,
    },
    {
      label: '申请付款金额',
      prop: 'applyPayAmount',
      propDesc: 'applyPayAmountDesc', // 需要转换的列值的属性名
      value: [],
      type: 'number',
      minWidth: 160,
      filterProp: ['applyPayAmountMin', 'applyPayAmountMax'],
    },
    {
      label: '实际付款金额',
      prop: 'actualPayAmount',
      propDesc: 'actualPayAmountDesc',
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['actualPayAmountMin', 'actualPayAmountMax'],
    },
    {
      label: '实际付款日期',
      prop: 'actualPayDate',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['actualPayDateStartDate', 'actualPayDateEndDate'],
    },
    {
      label: '交易币种',
      prop: 'tradeCurrency',
      propDesc: 'tradeCurrencyDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect', // 筛选类型 input 输入项
      options: 'trade_currency',
    },
    {
      label: '支付凭据',
      prop: 'fileVos',
      minWidth: 100,
      value: '',
      type: 'input', // 筛选类型 input 输入项
      isFilter: false,
      isSort: false,
      component: defineComponent({
        template: `
        <TableFileContent :readOnly="true" customClass="list-left" :fileUrlsArray="fileArr()"
        key-name="orderArapFileName" key-url="orderArapFileUrl" key-id="orderArapFileId" > </TableFileContent>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup(props) {
          const fileArr = () => {
            let arr = [];
            if (props.row && props.row.fileVos) {
              arr = props.row.fileVos.filter((item) => {
                return item && item.orderArapFileName;
              });
            }
            return arr;
          };
          return { fileArr };
        },
      }),
    },
    {
      label: '申请人',
      prop: 'applyOperator',
      minWidth: 128,
      value: '',
      type: 'input',
    },
    {
      label: '申请时间',
      prop: 'applyTime',
      minWidth: 174,
      value: [],
      type: 'time',
      filterProp: ['applyTimeStartDate', 'applyTimeEndDate'],
    },
    {
      label: '审核人',
      prop: 'auditOperator',
      minWidth: 120,
      value: '',
      type: 'input',
    },
    {
      label: '审核时间',
      prop: 'auditTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['auditTimeStartDate', 'auditTimeEndDate'],
    },
  ];
  return { columnList, tableRef };
};
