import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!

  return (_openBlock(), _createBlock(_component_ErpList, {
    url: "/malicrm/apply/pay/queryFmApplyPaymentRecordListWithPage",
    columOptions: _ctx.columnList,
    ref: "tableRef",
    id: "payrecordList",
    onRowClick: _ctx.rowClick,
    colorRander: true
  }, {
    header: _withCtx(() => [
      (_ctx.hasPermission('erp:payment:exportPayment'))
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "erp-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.exportFileFn()))
          }, "导出"))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["columOptions", "onRowClick"]))
}